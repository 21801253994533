<template>
    <div class="top-navi">
      <div class="navi-item" @click="navigateTo('pieceInfo')">
        <h3>棋子信息</h3>
      </div>
      <div class="navi-item" @click="navigateTo('equipmentInfo')">
        <h3>装备信息</h3>
      </div>
      <div class="navi-item" @click="navigateTo('synergyInfo')">
        <h3>羁绊信息</h3>
      </div>
      <div class="navi-item" @click="navigateTo('levelProbability')">
        <h3>等级概率信息</h3>
      </div>
      <div class="navi-item" @click="navigateTo('futureUpdates')">
        <h3>未来改动</h3>
      </div>
    </div>
  </template>
  
  <script>
  export default {
    name: 'TopNavi',
    methods: {
      navigateTo(target) {
        this.$router.push({ name: target });
      },
    },
  };
  </script>
  
  <style scoped>
  .top-navi {
    display: flex;
    justify-content: space-around;
    background-color: #333;
    color: #fff;
    padding: 20px 0;
  }
  
  .navi-item {
    flex: 1;
    text-align: center;
    cursor: pointer;
  }
  
  .navi-item h3 {
    margin-bottom: 10px;
  }
  </style>