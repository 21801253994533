import { createMemoryHistory, createRouter } from "vue-router";

import HomePage from "./components/HomePage.vue";
import JCC from "./components/JCC.vue";
import TFT from "./components/TFT.vue";
import DOTA from "./components/DOTA.vue";
import WZRY from "./components/WZRY.vue";
import NEW1 from "./HTML/TFT.HTML"

const routes = [
  { path: "/HomePage", component: HomePage },
  { path: "/JCC", component: JCC },
  { path: "/TFT", component: TFT },
  { path: "/DOTA", component: DOTA },
  { path: "/WZRY", component: WZRY },
  {path: "/New", component: NEW1}
];

const router = createRouter({
  history: createMemoryHistory(),
  routes,
});

export default router;
