<template>
  <div class="container">
    <!-- <a ref="/"></a> -->
    <RouterLink to="/TFT">
      <div class="image-wrapper">
        <img src="../assets/image1.jpg" alt="Image 1" />
      </div>
    </RouterLink>
    <RouterLink to="JCC">
      <div class="image-wrapper">
        <img src="../assets/image1.jpg" alt="Image 2" />
      </div>
    </RouterLink>
    <RouterLink to="WZRY">
      <div class="image-wrapper">
        <img src="../assets/image1.jpg" alt="Image 3" />
      </div>
    </RouterLink>
    <RouterLink to="DOTA">
      <div class="image-wrapper">
        <img
          src="../assets/image1.jpg
        "
          alt="Image 4"
        />
      </div>
    </RouterLink>
  </div>
</template>
<script></script>

<style scoped>
.container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  width: 100vw;
  height: 100vh;
  overflow: visible;
  background-color: #000; /* 添加黑色背景 */
}

.image-wrapper {
  position: relative;
  overflow: hidden;
  cursor: pointer;
  border-radius: 10px; /* 添加圆角效果 */
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.5); /* 添加阴影效果 */
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* 添加过渡效果 */
}

.image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
  filter: grayscale(20%) brightness(80%); /* 添加滤镜效果 */
}

.image-wrapper:hover img {
  transform: scale(1.1);
  filter: grayscale(0%) brightness(100%); /* 移除滤镜效果 */
}

.image-wrapper:hover {
  transform: translateY(-10px); /* 添加向上移动效果 */
  box-shadow: 0 20px 40px rgba(0, 0, 0, 0.5); /* 增强阴影效果 */
}
</style>
